import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import Menu from "../header/menu";
import PartyHero from "../hero/partyHero";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Footer from "../footer/footer";

import PartyForm from "../../form/partyForm";

const BlockContent = require("@sanity/block-content-to-react");

const Party = () => {
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type in ["party"]]`)
            .then((data) => {
                setPostData(data);
                console.log(data);
            })
            .catch(console.error);

        return () => {};
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <div className="party">
            <Menu></Menu>
            {postData && (
                <PartyHero headline={postData[0].partyHeadline} img={urlFor(postData[0].partyBG)}>
                    <div className="headerWrapper ">
                        <h1 className="font-slab flex flex-col items-center text-center text-3xl sm:text-4xl md:text-6xl lg:text-6xl xl:text-7xl 3xl:text-9xl text-white font-bold scale-in-center">
                            <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
                        </h1>
                    </div>
                </PartyHero>
            )}
            <div className="container mx-auto grid grid-cols-12 relative md:pt-16 mb-32 row-auto">
                {postData && (
                    <>
                        <div className="col-span-12 md:col-span-6 p-6">
                            <img src={urlFor(postData[0].firstImg)} alt="" />
                        </div>
                        <div className="col-span-12 md:col-span-6  p-6 flex  flex-col justify-center">
                            <h1 className="mb-5">{postData[0].partyHeadline}</h1>
                            <BlockContent blocks={postData[0].partyText} />,
                        </div>
                    </>
                )}
                <PartyForm></PartyForm>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Party;
