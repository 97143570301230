import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

import Home from "./components/sites/home";
import Party from "./components/sites/party";
import Impressum from "./components/sites/impressum";
import Datenschutz from "./components/sites/datenschutz";

import PagiTest from "./components/elements/pagiTest";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<Home />} exact path="/"></Route>
                <Route element={<Party />} path="/partyservice"></Route>
                <Route element={<Impressum />} path="/impressum"></Route>
                <Route element={<Datenschutz />} path="/datenschutz"></Route>
            </Routes>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#881f13" }}
                buttonStyle={{ color: "#881f13", fontSize: "13px" }}
                expires={150}
            >
                Wir verwenden Cookies, um Ihnen die bestmögliche Nutzungserfahrung zu bieten.
                <span style={{ fontSize: "10px" }}>
                    Sie stimmen der Nutzung von Cookies und unseren Datenschutzbestimmungen zu
                </span>
            </CookieConsent>
            {/* <PagiTest fetcher="schweinefleisch"></PagiTest> */}
        </div>
    );
}

export default App;
