import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Calender = (props) => {
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className={props.WrapperKlasse}>
            <label className={`${props.labelKlasse} font-semibold`} for={props.name}>
                {props.children}
            </label>
            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
            <div className={`${props.inputKlasse} `}>
                <DatePicker
                    className="border w-full p-2"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    name={props.name}
                />
            </div>
        </div>
    );
};

export default Calender;
