import InputNumber from "rc-input-number";

const RadioInput = (props) => {
    return (
        <>
            <input
                className="mr-3"
                onChange={props.onChange}
                onBlur={props.onBlur}
                type="radio"
                id={props.id}
                name={props.name}
                value={props.value}
            ></input>
            <label className="font-semibold" for={props.for}>
                {props.label}
            </label>
        </>
    );
};

export default RadioInput;
