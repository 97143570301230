import React, { useState, useEffect, useRef } from "react";

// import Swiper from "../elements/swiper";
import SwipeElement from "../elements/swipeElement";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import ScrollAnimation from "react-animate-on-scroll";

import AngebotHolder from "../elements/angebotHolder";
import AngebotGridElement from "../elements/angebotGridElement";

import Slider from "react-slick";
import Fade from "react-reveal/Fade";

import "animate.css/animate.min.css";

const Angebote = (props) => {
    const [postData, setPostData] = useState(null);
    const [zeitRaum, setZeitraum] = useState(null);
    const sliderRef = useRef(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["angebote"]]
              `
            )
            .then((data) => {
                setPostData(data);
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["angeboteSettings"]]
              `
            )
            .then((data) => {
                setZeitraum(data);
            })
            .catch(console.error);
    }, []);

    const gotoNext = () => {
        sliderRef.current.slickNext();
    };
    const gotoPrevious = () => {
        sliderRef.current.slickPrev();
    };

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        useTransform: true,
        centerMode: true,
        centerPadding: "180px",
        onSwipe: () => {
            console.log("swipe");
        },
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: "120px",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: "140px",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: "40px",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: "0",
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className=" pt-40 pb-20 sm:pb-60 sm:bg-black overflow-hidden" id="angebote">
            <ScrollAnimation animateIn="fadeInLeftBig" offset={300} animateOnce={true}>
                <div className=" text-left mx-auto container mb-10 sm:mb-20">
                    <h1 className="font-slab text-center sm:text-left relative text-2xl sm:text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-regular text-white">
                        {zeitRaum && zeitRaum[0].title}
                    </h1>
                </div>{" "}
            </ScrollAnimation>

            <AngebotHolder>
                {postData &&
                    postData.map((e, i) => {
                        return <AngebotGridElement title={e.title} price={e.price}></AngebotGridElement>;
                    })}
            </AngebotHolder>
            {/* 
            {postData && (
                <ScrollAnimation animateIn="fadeIn" offset={300} animateOnce={true}>
                    <Slider {...settings} ref={sliderRef}>
                        {postData &&
                            postData.map((e, i) => {
                                return (
                                    <SwipeElement
                                        image={urlFor(e.image)}
                                        title={e.title}
                                        description={e.description}
                                        price={e.price}
                                        forwardClick={gotoNext}
                                        backClick={gotoPrevious}
                                    ></SwipeElement>
                                );
                            })}
                    </Slider>
                </ScrollAnimation>
            )} */}
        </div>
    );
};

export default Angebote;
