import React from "react";

import ModalGrid from "./modalGrid";

import { RiCloseFill } from "react-icons/ri";

const Modal = (props) => {
    return (
        <div
            style={props.style}
            className={`${props.klasse} modal rounded-xl  min-h-[50%]  container w-[90%] lg:w-full xl:w-3/4 px-4 pt-10 sm:p-10 bg-firebrick shadow z-40 absolute sm:fixed top-0  sm:mt-10 sm:left-1/2 sm:top-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2`}
        >
            <div
                className="closer right-5 top-5 text-2xl absolute cursor-pointer transition-all	 transform hover:scale-125"
                onClick={props.closeMe}
            >
                <RiCloseFill></RiCloseFill>
            </div>
            {props.children}
        </div>
    );
};

export default Modal;
