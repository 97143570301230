import React from "react";

const AngebotHolder = (props) => {
    return (
        <div
            className={`container m-auto relative transition-all duration-500 bg-opaque p-8  ${props.klasse}`}
            style={props.style}
        >
            <div className="innerWrapper borderWrapper border-opaqueWhite sm:border-2 sm:py-36 sm:pr-8 sm:pl-16 xl:pl-16 text-white md:pl-10 pl-8 ">
                <div className="w-full grid grid-cols-12">{props.children}</div>
            </div>
        </div>
    );
};

export default AngebotHolder;
