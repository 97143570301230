import React from "react";

import Headline from "../elements/headline";
import Button from "../elements/button";

const Service = () => {
    return (
        <div className="mb-20 sm:mb-36 p-5 sm:p-10 container mx-auto bg-primaryRed text-center">
            <div className="inner p-10 sm:p-20 border border-white text-center flex justify-center flex-col align-items-center">
                <Headline klasse="text-white">Unser Partyservice</Headline>
                <div className="btnWrapper w-4/5 sm:w-2/4 flex justify-center m-auto mt-10 sm:mt-20">
                    <Button link="./partyservice" klasse="border">
                        Mehr erfahren
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Service;
