import React, { useState, useEffect, useRef } from "react";

import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

import { IoIosArrowDroprightCircle } from "react-icons/io";

import Fade from "react-reveal/Fade";
import ScrollAnimation from "react-animate-on-scroll";

import Overlay from "../elements/overlay";
import Modal from "../elements/modal";
import ModalGrid from "../elements/modalGrid";
import Accordion from "../elements/accordion";

import PagiTest from "../elements/pagiTest";
// import useMobileDetect from "use-mobile-detect-hook";

const Produkte = (props) => {
    const [postData, setPostData] = useState(null);
    const [huhn, setHuhn] = useState(null);
    const [schwein, setSchwein] = useState(null);
    const [wurst, setWurst] = useState(null);
    const [fisch, setFisch] = useState(null);
    const [salat, setSalat] = useState(null);

    const [katBG, setKatBG] = useState(null);

    const [dataset, setDataSet] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const [showAccordions, setShowAccordions] = useState(false);
    const [showAccordions2, setShowAccordions2] = useState(false);
    const [showAccordions3, setShowAccordions3] = useState(false);
    const [showAccordions4, setShowAccordions4] = useState(false);
    const [showAccordions5, setShowAccordions5] = useState(false);
    const [showAccordions6, setShowAccordions6] = useState(false);

    // const detectMobile = useMobileDetect();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["rindfleisch"]]
              `
            )
            .then((data) => {
                setPostData(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["huehnerfleisch"]]
              `
            )
            .then((data) => {
                setHuhn(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["schweinefleisch"]]
              `
            )
            .then((data) => {
                setSchwein(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["wuerstchen"]]
              `
            )
            .then((data) => {
                setWurst(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["fisch"]]
              `
            )
            .then((data) => {
                setFisch(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["salatProdukt"]]
              `
            )
            .then((data) => {
                setSalat(data.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch(console.error);
        sanityClient
            .fetch(
                `*[_type in ["heroBG"]]
              `
            )
            .then((data) => {
                setKatBG(data);
                console.log(data[0].rindBG);
            })
            .catch(console.error);

        console.log(detectMobile());
        console.log(window.innerWidth);
    }, []);

    function detectMobile() {
        return window.innerWidth <= 480 ? true : false;
    }

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    function overlaying(e) {
        setDataSet(e.currentTarget.dataset.id);
        setShowModal(true);
        setShowOverlay(true);
    }

    function overlayingOff() {
        setShowModal(false);
        setShowOverlay(false);
    }

    function showAccordion(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions ? setShowAccordions(false) : setShowAccordions(true);
    }

    function showAccordion2(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions2 ? setShowAccordions2(false) : setShowAccordions2(true);
    }

    function showAccordion3(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions3 ? setShowAccordions3(false) : setShowAccordions3(true);
    }

    function showAccordion4(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions4 ? setShowAccordions4(false) : setShowAccordions4(true);
    }
    function showAccordion5(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions5 ? setShowAccordions5(false) : setShowAccordions5(true);
    }
    function showAccordion6(e) {
        e.currentTarget.children[0].children[1].classList.toggle("rotate");
        showAccordions6 ? setShowAccordions6(false) : setShowAccordions6(true);
    }

    return (
        <>
            {showModal && dataset === "postData" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">RINDFLEISCH</span>{" "}
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    {/* <p className="text-xl">Lorem ipsum dolor sit amet</p> */}
                    {/* <ModalGrid data={postData}></ModalGrid> */}
                    <PagiTest fetcher="rindfleisch"></PagiTest>
                </Modal>
            )}
            {showModal && dataset === "schwein" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">SCHWEINEFLEISCH</span>{" "}
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    {/* <ModalGrid data={schwein}></ModalGrid> */}
                    <PagiTest fetcher="schweinefleisch"></PagiTest>
                </Modal>
            )}
            {showModal && dataset === "huhn" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">GEFLÜGEL & FISCH</span>{" "}
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    {/* <ModalGrid data={huhn}></ModalGrid> */}
                    <PagiTest fetcher="huehnerfleisch"></PagiTest>
                </Modal>
            )}
            {showModal && dataset === "wurst" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">WURST</span>
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    {/* <ModalGrid data={wurst}></ModalGrid> */}
                    <PagiTest fetcher="wuerstchen"></PagiTest>
                </Modal>
            )}
            {showModal && dataset === "fisch" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">WILD & LAMM</span>{" "}
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    <ModalGrid data={fisch}></ModalGrid>
                </Modal>
            )}
            {showModal && dataset === "salat" && (
                <Modal closeMe={overlayingOff}>
                    <h1 className="mb-5">
                        <span className="font-semibold">SALATE</span>
                        {/* <span className="text-2xl">- lorem ipsum</span>{" "} */}
                    </h1>
                    <hr />
                    <ModalGrid data={salat}></ModalGrid>
                </Modal>
            )}
            {showOverlay && <Overlay onClick={overlayingOff}></Overlay>}
            <div className="w-full bg-dark relative py-32" id="produkte">
                <ScrollAnimation animateIn="fadeInLeftBig" offset={300} animateOnce={true}>
                    <div className=" text-right mx-auto container sm:mb-20">
                        <h1 className="font-slab text-center sm:text-left relative text-2xl sm:text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-regular text-white">
                            Unsere Produkte
                        </h1>
                    </div>{" "}
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" offset={300} animateOnce={true}>
                    <div className="mt-10 sm:mt-20  mb-20 sm:mb-60 container mx-auto">
                        <div className="block cursor-pointer productGrid sm:grid sm:grid-cols-12 sm:grid-rows-2 sm:gap-4 sm:h-96 ">
                            <div
                                data-id="postData"
                                className="sm:col-span-4  relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion(e)}
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <h2 className="font-bold text-white font-slab text-3xl">Rind</h2>
                                    <div className="iconOverview inline-block text-3xl text-white">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {postData && katBG && (
                                    <div
                                        className="rindOverview h-full bg-cover "
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].rindBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="postData"
                                    className="productOverlay w-full h-full mix-blend-multiply absolute top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions ? "max-h-0 overflow-hidden" : " max-h-100 pb-10"}>
                                    <ModalGrid data={postData}></ModalGrid>
                                </Accordion>
                            )}
                            <div
                                className="col-span-4 cursor-pointer  relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion2(e)}
                                data-id="schwein"
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <h2 className="font-bold text-white font-slab text-3xl">Schwein</h2>
                                    <div className="iconOverview inline-block text-3xl text-white">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {schwein && katBG && (
                                    <div
                                        className="rindOverview h-full bg-cover"
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].schweinBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="schwein"
                                    className="productOverlay w-full h-full mix-blend-multiply absolute top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions2 ? " max-h-0 overflow-hidden" : "max-h-100 pb-10"}>
                                    <ModalGrid data={schwein}></ModalGrid>
                                </Accordion>
                            )}
                            <div
                                className="col-span-4 relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion3(e)}
                                data-id="huhn"
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <h2 className="font-bold text-white font-slab text-3xl">Geflügel & Fisch</h2>
                                    <div className="iconOverview inline-block text-3xl text-white">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {huhn && katBG && (
                                    <div
                                        className="rindOverview h-full bg-cover"
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].huhnfischBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="huhn"
                                    className="productOverlay w-full h-full mix-blend-multiply absolute top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions3 ? " max-h-0 overflow-hidden" : "max-h-100 pb-10"}>
                                    <ModalGrid data={huhn}></ModalGrid>
                                </Accordion>
                            )}
                            <div
                                className="col-span-4 cursor-pointer relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion4(e)}
                                data-id="wurst"
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <h2 className="font-bold text-white font-slab text-3xl" data-id="wurst">
                                        Wurst
                                    </h2>
                                    <div className="iconOverview inline-block text-3xl text-white" data-id="wurst">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {wurst && katBG && (
                                    <div
                                        data-id="wurst"
                                        className="rindOverview h-full bg-cover"
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].wurstBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="wurst"
                                    className="productOverlay w-full h-full absolute mix-blend-multiply top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions4 ? " max-h-0 overflow-hidden" : "max-h-100 pb-10"}>
                                    <ModalGrid data={wurst}></ModalGrid>
                                </Accordion>
                            )}
                            <div
                                className="col-span-4 cursor-pointer relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion5(e)}
                                data-id="fisch"
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <h2 className="font-bold text-white font-slab text-3xl" data-id="fisch">
                                        Wild & Lamm
                                    </h2>
                                    <div className="iconOverview inline-block text-3xl text-white" data-id="fisch">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {fisch && katBG && (
                                    <div
                                        data-id="fisch"
                                        className="rindOverview h-full bg-cover"
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].wildlammBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="wurst"
                                    className="productOverlay w-full h-full absolute mix-blend-multiply top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions5 ? " max-h-0 overflow-hidden" : "max-h-100 pb-10"}>
                                    <ModalGrid data={fisch}></ModalGrid>
                                </Accordion>
                            )}
                            <div
                                className="col-span-4 cursor-pointer relative overviewItem h-48 sm:h-auto mb-2 sm:mb-0"
                                onClick={!detectMobile() ? (e) => overlaying(e) : (e) => showAccordion6(e)}
                                data-id="salat"
                            >
                                <div className="textOverview text-center z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <h2 className="font-bold text-white font-slab text-3xl" data-id="salat">
                                        Salat
                                    </h2>
                                    <div className="iconOverview inline-block text-3xl text-white" data-id="salat">
                                        <IoIosArrowDroprightCircle></IoIosArrowDroprightCircle>
                                    </div>
                                </div>
                                {salat && katBG && (
                                    <div
                                        data-id="salat"
                                        className="rindOverview h-full bg-cover"
                                        style={{ backgroundImage: `url(${urlFor(katBG[0].salatBG)})` }}
                                    ></div>
                                )}
                                <div
                                    data-id="salat"
                                    className="productOverlay w-full h-full absolute mix-blend-multiply top-0 bg-black opacity-70"
                                ></div>
                            </div>
                            {detectMobile() && (
                                <Accordion klasse={!showAccordions6 ? " max-h-0 overflow-hidden" : "max-h-100 pb-10"}>
                                    <ModalGrid data={salat}></ModalGrid>
                                </Accordion>
                            )}
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </>
    );
};

export default Produkte;
