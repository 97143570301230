import React from "react";

const Accordion = (props) => {
    return (
        <div className={`w-full transition-all duration-500 bg-firebrick  ${props.klasse}`} style={props.style}>
            {props.children}
        </div>
    );
};

export default Accordion;
