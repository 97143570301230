import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Menu from "../header/menu";
import Hero from "../hero/hero";
import Angebote from "../angebote/angebote";
import Produkte from "../produkte/produkte";
import Menue from "../menu/menu";
import Service from "../service/service";
import About from "../about/about";
import Footer from "../footer/footer";

import PopUp from "../popup";

function Home() {
    return (
        <div className="Home">
            <Menu></Menu>
            <Hero></Hero>
            <div className=" overflow-x-hidden">
                <PopUp></PopUp>
                <Angebote></Angebote>
                <Produkte></Produkte>
                <Menue></Menue>
                <Service></Service>
                <About></About>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default Home;
