import React from "react";
import ModalGridElement from "./modalGridElement";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const ModalGrid = (props) => {
    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <div className="modalGrid  grid grid-cols-12 gap-3 px-3">
            {props.data &&
                props.data.map((e) => {
                    return (
                        <ModalGridElement
                            // image={urlFor(e.image).width(200).height(200)}
                            title={e.title}
                            price={e.price}
                        ></ModalGridElement>
                    );
                })}
        </div>
    );
};

export default ModalGrid;
