import React from "react";

const ModalGridElement = (props) => {
    return (
        <div className="col-span-6 sm:col-span-2 md:col-span-4 lg:col-span-3">
            <img className="mb-3 mt-3" src={props.image} alt={props.imageAlt} />
            <h3 className="font-bold text-sm">{props.title}</h3>
            <span className="text-sm ">{props.price}</span>
        </div>
    );
};

export default ModalGridElement;
