import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const PagiTest = (props) => {
    const [itemsAll, setItemsAll] = useState([]);
    const [items, setItems] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);

    const itemsPerPage = 20;

    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["${props.fetcher}"]]
              `
            )
            .then((data) => {
                setItemsAll(data);
                const chunks = sliceIntoChunks(
                    data.sort((a, b) => (a.title > b.title ? 1 : -1)),
                    itemsPerPage
                );
                setItems(chunks);
                // setItems(data.sort((a, b) => (a.title > b.title ? 1 : -1)));

                console.log(sliceIntoChunks(data, itemsPerPage));
            });
        console.log(items);
    }, []);

    const handlePageClick = (e) => {
        console.log(Math.ceil(items.length / itemsPerPage));
        console.log(e.selected);
        setCurrentPage(e.selected);
    };

    return (
        <div>
            <div className="modalGrid  grid grid-cols-12 gap-3 px-3 mt-5">
                {items && (
                    <>
                        {items[currentPage].map((item, index) => {
                            if (index < itemsPerPage) {
                                return (
                                    <div className="col-span-6 sm:col-span-2 md:col-span-4 lg:col-span-3">
                                        <h3 className="font-bold text-sm">{item.title}</h3>
                                        <span className="text-sm ">{item.price}</span>
                                    </div>
                                );
                            }
                        })}
                    </>
                )}
            </div>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={Math.ceil(itemsAll.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination border flex justify-center items-center mt-5"}
                pageClassName={"page-item border p-2 w-10 text-center opacity-50"}
                pageLinkClassName={"page-link "}
                previousClassName={"page-item pr-5"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item pl-5"}
                nextLinkClassName={"page-link"}
                activeClassName={"active font-bold opacity-100"}
            ></ReactPaginate>
        </div>
    );
};

export default PagiTest;
