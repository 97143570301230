import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Sticky from "react-stickynode";

const PartyHero = (props) => {
    const [postData, setPostData] = useState(null);
    const headerRef = useRef();

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 400 && position < 1260) {
            // overlayRef.current.style.opacity = 0.4 + position / 2 / 1100;
            // overlayRef.current.style.opacity = 0.7 - position / 2 / 1000;
        }
        // headerRef.current.style.transform = `scale(${1 - position / 2 / 200})`;
        // bueffelRef.current.style.transform = `translate( 0px,${1 - position}px)`;
        if (position > 300) {
            headerRef.current.classList.remove("slide-in-right");
            headerRef.current.classList.add("slide-out-right");
            // bueffelRef.current.classList.remove("slide-in-left");
            // bueffelRef.current.classList.add("slide-out-left");
            // hoursRef.current.classList.remove("slide-in-right");
            // hoursRef.current.classList.add("slide-out-right-delayed");
            // buttonRef.current.classList.remove("fade-in");
            // buttonRef.current.classList.add("fade-out");
            // bueffelRef.current.style.transform = `scale(${1 - position / 2 / 200})`;
        }
        if (position <= 300 && headerRef.current.classList.contains("slide-out-right")) {
            headerRef.current.classList.remove("slide-out-right");
            headerRef.current.classList.add("slide-in-right");
            // bueffelRef.current.classList.remove("slide-out-left");
            // bueffelRef.current.classList.add("slide-in-left");
            // hoursRef.current.classList.remove("slide-out-right-delayed");
            // hoursRef.current.classList.add("slide-in-right");
            // buttonRef.current.classList.remove("fade-out");
            // buttonRef.current.classList.add("fade-in");
        }
        if (position > 800) {
            headerRef.current.style.display = "none";
        } else {
            headerRef.current.style.display = "block";
        }

        // if (position >= 800) {
        //     wrapperRef.current.style.left = "-9999px";
        // } else if (position <= 400) {
        //     wrapperRef.current.style.left = "50%";
        // }
        // if (indicatorRef.current.getBoundingClientRect().top + indicatorRef.current.clientHeight / 2 <= 0) {
        //     indicatorRef.current.style.position = "fixed";
        //     indicatorRef.current.style.top = 0;
        //     console.log("fixed");
        // } else {
        //     indicatorRef.current.style.position = "absolute";
        // }
    };

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type in ["heroBG", "heroText"]]{
                    heroBGImage,
                     "video": video.asset->url,
                     heroHeadline,
                     heroOpeningHours,
                     heroButtonText
      
             }
              `
            )
            .then((data) => {
                setPostData(data);
            })
            .catch(console.error);

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const builder = imageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <div className="partyHero  w-full h-screen overflow-hidden">
            <div className="videoOverlay h-auto w-full absolute bg-black opacity-70 z-10 h-full"></div>
            <div className="backGround h-full bg-cover	bg-center	" style={{ backgroundImage: "url(" + props.img + ")" }}>
                <div className="wrap flex justify-center align-center flex-col text-center z-10 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    {" "}
                    <div ref={headerRef}>
                        <h1 className="font-slab flex flex-col items-center text-center text-3xl sm:text-4xl md:text-6xl lg:text-6xl xl:text-7xl 3xl:text-9xl text-white font-bold scale-in-center">
                            {props.headline}
                            <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
                        </h1>
                    </div>
                </div>
                {/* <img src={props.img} className={`${props.klasse}`} alt="" /> */}
            </div>
        </div>
    );
};

export default PartyHero;
