import InputNumber from "rc-input-number";

const NumberInput = (props) => {
    return (
        <div className="input w-full block grid grid-cols-12 px-2 py-1 flex items-center text-sm mb-2 sm:mb-0">
            <label className="col-span-6 sm:col-span-4 font-semibold" for={props.name}>
                {props.title}
            </label>
            <div className="col-span-6 sm:col-span-4">{props.price}</div>
            {/* <InputNumber defaultValue={0} controls={true}></InputNumber> */}
            <div className="col-span-12 sm:col-span-4 flex flex-end">
                <input
                    className="border numberForm text-right p-2 w-full sm:w-auto"
                    data-price={parseFloat(props.price.replace(",", "."))}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    type="number"
                    id={props.name}
                    name={props.name}
                    placeholder="0"
                    ref={props.ref}
                ></input>
            </div>
        </div>
    );
};

export default NumberInput;
