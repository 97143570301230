import React from "react";

const AngebotGridElement = (props) => {
    return (
        <div className={`col-span-12 md:col-span-6 lg:col-span-3 mb-8  ${props.klasse}`} style={props.style}>
            <h2 className="font-bold text-2xl mb-2 font-slab text-opaqueWhite">{props.title}</h2>
            <span className="mt-4 block font-bold ">{props.price}</span>
        </div>
    );
};

export default AngebotGridElement;
