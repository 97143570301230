import React, { useState, useEffect, useRef } from "react";

import Logo from "../../assets/Logo.png";
import MobileMenu from "./mobileMenu";

import { GiHamburgerMenu } from "react-icons/gi";

const MenuFixed = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();

    function showMobile() {
        // document.querySelector("#mobileMenu").classList.remove("slide-out-right");
        // document.querySelector("#mobileMenu").classList.add("slide-in-right");
        setShowMenu(true);
        console.log(menuRef);
    }
    function hideMobile() {
        document.querySelector("#mobileMenu").classList.add("slide-out-right");
        setTimeout(() => {
            setShowMenu(false);
        }, 300);
    }

    useEffect(() => {
        // window.addEventListener("scroll", isSticky, true);
        // // window.addEventListener("scroll", test, true);
        // console.log(window.innerHeight);
        const menuBar = document.querySelector("#menu");
        menuBar.classList.remove("is-sticky");
        return () => {
            window.removeEventListener("scroll", isSticky);
            const menuBar = document.querySelector("#menu");
            menuBar.classList.remove("is-sticky");
        };
    }, []);

    const isSticky = (e) => {
        const menuBar = document.querySelector("#menu");
        const scrollTop = window.scrollY;
        console.log("Ich greife");
        scrollTop >= window.innerHeight - 1120
            ? menuBar.classList.add("is-sticky")
            : menuBar.classList.remove("is-sticky");
    };

    function test() {
        console.log("§test");
    }

    return (
        <div ref={menuRef} id="menu" className="menuWrapper relative w-full ">
            {showMenu && (
                <MobileMenu
                    onClick={hideMobile}
                    onClickClose={() => setShowMenu(false)}
                    klasse={showMenu ? "slide-in-right" : "slide-out-right"}
                ></MobileMenu>
            )}
            <div
                onClick={showMobile}
                className={`hamburger ${props.klasse} lg:hidden text-black absolute right-8 top-4 z-50 text-xl cursor-pointer`}
            >
                <GiHamburgerMenu></GiHamburgerMenu>
            </div>
            <div id="stickyWrapper" className="w-full">
                <div id="menu-bar" className="sm:w-3/4 md:w-2/3 2xl:w-2/4 3xl:w-1/3 m-auto z-20 ">
                    <div className="menuContainer text-center lg:grid grid-cols-12">
                        <div className="left col-span-0 hidden lg:flex sm:col-span-4 justify-around font-slab font-bold text-dark pt-10 text-lg slide-in-top">
                            <a href="./#produkte">
                                <span className="hover:text-brightRed slide-in-top">Produkte</span>
                            </a>
                            <a href="./#mittagsmenue">
                                <span className="hover:text-brightRed">Mittagsmenü</span>
                            </a>
                        </div>
                        <div className="middle col-span-4 flex justify-center pt-2 mx-auto z-20 w-2/3 sm:w-full">
                            <a href="./" className="flex justify-center">
                                <img className="scale-in-center" src={Logo} alt="" />
                            </a>
                        </div>
                        <div className="right col-span-0 hidden lg:flex sm:col-span-4 justify-around font-slab font-bold text-dark pt-10 text-lg slide-in-top">
                            <a href="./partyservice">
                                {" "}
                                <span className="hover:text-brightRed">Partyservice</span>
                            </a>
                            <a href="./#kontakt">
                                {" "}
                                <span className="hover:text-brightRed">Kontakt</span>
                            </a>
                        </div>
                    </div>
                    {/* <hr className="absolute top-20 w-full hidden lg:block " /> */}
                </div>
            </div>
            <div className="stickyBackground"></div>
        </div>
    );
};

export default MenuFixed;
