import React from "react";

const PictureTag = (props) => {
    return (
        <picture className={props.klasse}>
            <source media={props.media} srcSet={props.srcSet} />
            <img className={props.imgKlasse} src={props.src} alt={props.alt} />
        </picture>
    );
};

export default PictureTag;
