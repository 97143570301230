import React from "react";

const Headline = (props) => {
    return (
        <h1
            className={`font-slab text-3xl sm:text-4xl md:text-6xl lg:text-6xl xl:text-7xl font-semibold ${props.klasse}`}
        >
            {props.children}
        </h1>
    );
};

export default Headline;
