import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// import Icon from "../icons/icon";

import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

import Logo from "../../assets/Logo.png";

function Footer(props) {
    const UsePathname = () => {
        const location = useLocation();
        return location.pathname;
    };
    useEffect(() => {}, []);

    function checkRoute() {
        switch (UsePathname()) {
            case "/partyservice":
                return "../";
            case "/impressum":
                return "../";
            case "/datenschutz":
                return "../";
            default:
                return "./";
        }
    }

    const linkTarget = {
        pathname: "/datenschutz",
        key: uuidv4(), // we could use Math.random, but that's not guaranteed unique.
        state: {
            applied: true,
        },
    };

    return (
        <div
            className={`footerWrapper w-full p-10 2xl:px-40 pb-40
             bg-darkBG text-brighttext text-xs leading-loose ${props.wrapperClass}`}
            id="contact"
        >
            <hr />
            <div className="container mx-auto grid sm:grid-cols-1 md:grid-cols-4 gap-4 mt-10">
                <div className="footer1 p-4">
                    <h4 className="font-bold text-sm mb-8 uppercase">Jörg Freier - Fleisch & Wurstwaren</h4>
                    <p>
                        Kammerrat Beck Straße 8
                        <br />
                        09376 Oelsnitz <br />
                    </p>
                    <div className="detailWrapper flex mt-8">
                        {/* <Icon icon="telephone" margin="mr-4" color="text-darkerbeige text-lg"></Icon> */}
                        <div className="mr-4 flex items-center">
                            <BsTelephoneFill></BsTelephoneFill>
                        </div>
                        <div className="right">
                            <a href="tel:+493729812792"> +49 37298 12792</a>
                        </div>
                    </div>
                    <div className="detailWrapper flex">
                        {/* <Icon icon="envelope" margin="mr-4" color="text-darkerbeige text-lg"></Icon> */}
                        <div className="mr-4 flex items-center">
                            <GrMail></GrMail>
                        </div>
                        <div className="right">
                            <a href="mailto:office@fleischerei-freier.de">office@fleischerei-freier.de</a>
                        </div>
                    </div>
                </div>
                <div className="footer2 p-4">
                    <h4 className="font-bold text-sm mb-8 uppercase">Öffnungszeiten</h4>
                    <p>
                        Montag bis Donnerstag:
                        <br />
                        08:30 - 12:30 | 14:30 - 18:00
                    </p>
                    <p>
                        Freitag:
                        <br />
                        08:30 - 18:00
                    </p>
                    <p>
                        Samstag:
                        <br />
                        08.00 - 11:00
                    </p>
                </div>
                <div className="footer3 p-4">
                    <h4 className="font-bold text-sm mb-8 uppercase">Social Media</h4>
                    <div className="flex">
                        <div className="mr-5">
                            <a href="https://www.facebook.com/FreierFleischer/">
                                <BsFacebook></BsFacebook>
                            </a>
                        </div>
                        <div className="mr-5">
                            <a href="https://www.instagram.com/fleischereifreier/">
                                <AiFillInstagram></AiFillInstagram>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer4 p-4">
                    <img src={Logo} className="mb-5" alt="Logo" />
                    <Link to={`/impressum`} className="block">
                        Impressum
                    </Link>
                    <Link to={linkTarget} className="block">
                        Datenschutz
                    </Link>
                    {/* {UsePathname() === "/partyservice" ? (
                        <>
                            <Link to="../impressum" className="block">
                                Impressum
                            </Link>
                            <Link to="../datenschutz" className="block">
                                Datenschutz
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="./impressum" className="block">
                                Impressum
                            </Link>
                            <Link to="./datenschutz" className="block">
                                Datenschutz
                            </Link>
                        </>
                    )} */}
                    {/* <Link to="./datenschutz" className="mb-5 block">
                        Datenschutz
                    </Link> */}
                </div>
            </div>
        </div>
    );
}

export default Footer;
