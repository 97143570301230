import React, { useState, useRef } from "react";

import { MdClose } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const MobileMenu = (props) => {
    const menuRef = useRef();

    function hideMe() {
        menuRef.current.classList.remove("slide-in-right");
        menuRef.current.classList.add("slide-out-right");
    }

    return (
        <div
            id="mobileMenu"
            className={`mobileMenu fixed w-full h-screen bg-greenish z-30  ${props.klasse}`}
            ref={menuRef}
        >
            <div onClick={props.onClick} className="closer absolute right-8 top-8 text-4xl cursor-pointer text-white">
                <MdClose></MdClose>
            </div>

            <div className="linkWrapper absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-slab text-4xl">
                <a href="./#produkte" onClick={props.onClickClose}>
                    {" "}
                    <span className="pb-3 pt-3 block text-white">Produkte</span>
                </a>
                <hr className="text-white border-white white" />
                <a href="./#mittagsmenue" onClick={props.onClickClose}>
                    {" "}
                    <span className="pb-3 pt-3 block text-white">Mittagsmenü</span>
                </a>
                <hr />
                <a href="./partyservice" onClick={props.onClickClose}>
                    {" "}
                    <span className="pb-3 pt-3 block text-white">Partyservice</span>
                </a>
                <hr />
                <a href="./#kontakt" onClick={props.onClickClose}>
                    {" "}
                    <span className="pb-3 pt-3 block text-white">Kontakt</span>
                </a>
            </div>
            <div className="bottomWrapper text-white text-sm flex w-4/5 justify-around items-center h-8 absolute left-1/2 transform -translate-x-1/2 bottom-8  ">
                {/* <span className=" text-white">Impressum</span>
                <span className=" text-white">Datenschutz</span> */}
                {/* <FaFacebook className="mr-4"></FaFacebook>
                <AiFillInstagram></AiFillInstagram> */}
            </div>
        </div>
    );
};

export default MobileMenu;
