import React, { useState, useEffect } from "react";
import Overlay from "../elements/overlay";
import Modal from "../elements/modal";

import Baum from "../../assets/baum_big_tiny.jpg";

const Popup = () => {
    const [showOverlay, setShowOverlay] = useState(true);

    function overlayingOff() {
        setShowOverlay(false);
    }
    return (
        <>
            {showOverlay && (
                <>
                    <Modal closeMe={overlayingOff} klasse="bg-cover" style={{ backgroundImage: `url(${Baum})` }}>
                        <span className="font-slab font-bold text-primaryRed leading-10 text-2xl sm:text-4xl">
                            Weihnachten ist gerettet:
                            <br />
                            Gewinnt eure <strong className="uppercase font-extrabold">Weihnachtsgans!</strong>
                        </span>
                        <div className="text mt-4 sm:mt-8">
                            <p>Zum diesjährigen Weihnachtsfest erwarten euch köstliche Gewinne bei uns.</p>
                            <div className="grid grid-cols-12 mt-6">
                                <div className="col-span-3 sm:col-span-1  font-sans font-bold">1. Preis</div>
                                <div className="col-span-9 sm:col-span-11">
                                    1 frische deutsche Gans aus Freilandhaltung <br></br> (alternativ: 1
                                    Weihnachtsbraten im gleichen Wert)
                                </div>
                                <div className="col-span-3 sm:col-span-1 font-sans font-bold mt-2">2. Preis</div>
                                <div className="col-span-9 sm:col-span-11  mt-2">1 großer Präsentkorb</div>
                                <div className="col-span-3 sm:col-span-1 font-sans font-bold  mt-2">3. Preis</div>
                                <div className="col-span-9 sm:col-span-11 mt-2">
                                    1 Einkaufsgutschein in Höhe von 25 €
                                </div>
                                <div className="col-span-12 mt-4">… sowie weitere Preise.</div>
                            </div>
                            <h2 className="font-bold mt-6 mb-4">Wie könnt ihr gewinnen?</h2>
                            <p>
                                Vom <strong> 01.11. bis 23.12.2022</strong> erhaltet ihr bei uns im Geschäft einen
                                Sammelpass.{" "}
                            </p>
                            <ul className="list-disc pl-6 sm:pl-10 mt-4">
                                <li>
                                    Für jeden <strong> Einkauf über 10 €</strong> gibt’s einen Stempel in den
                                    Sammelpass.
                                </li>
                                <li>
                                    Ist der Sammelpass <strong> mit 6 Stempeln</strong> gefüllt, gebt ihr ihn bei uns im
                                    Geschäft ab.
                                </li>
                                <li>
                                    Die Auslosung erfolgt <strong> am 23.12.2022</strong> bei uns im Geschäft.
                                </li>
                            </ul>
                            <p className="mt-4 mb-6 sm:mb-0">
                                {" "}
                                Die Gewinner werden telefonisch benachrichtigt. Der Rechtsweg ist ausgeschlossen.
                            </p>
                        </div>
                    </Modal>
                    <Overlay onClick={overlayingOff}></Overlay>
                </>
            )}
        </>
    );
};

export default Popup;
