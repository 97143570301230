import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MenuFixed from "../header/menuFixed";
import Footer from "../footer/footer";

const Datenschutz = () => {
    useEffect(() => {
        // window.location.reload();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const menuBar = document.querySelector("#menu");
        menuBar.classList.remove("is-sticky");

        return () => {};
    }, []);

    return (
        <div className="datenschutz">
            <MenuFixed klasse="menuStuff"></MenuFixed>
            <div className="container mx-auto  relative px-5 md:pt-16 mb-32 row-auto">
                <div className="wrapper pt-20"></div>
                <h1>Datenschutzerklärung</h1>
                <h2 id="m14">Einleitung</h2>
                <p>
                    Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                    personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
                    welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                    Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch
                    insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
                    Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
                    "Onlineangebot“).
                </p>
                <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                <p>Stand: 4. April 2022</p>
                <h2>Inhaltsübersicht</h2>{" "}
                <ul class="index">
                    <li>
                        <a class="index-link" href="#m14">
                            Einleitung
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m3">
                            Verantwortlicher
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#mOverview">
                            Übersicht der Verarbeitungen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m13">
                            Maßgebliche Rechtsgrundlagen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m27">
                            Sicherheitsmaßnahmen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m25">
                            Übermittlung von personenbezogenen Daten
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m24">
                            Datenverarbeitung in Drittländern
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m12">
                            Löschung von Daten
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m134">
                            Einsatz von Cookies
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m225">
                            Bereitstellung des Onlineangebotes und Webhosting
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m182">
                            Kontakt- und Anfragenverwaltung
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m136">
                            Präsenzen in sozialen Netzwerken (Social Media)
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m15">
                            Änderung und Aktualisierung der Datenschutzerklärung
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m10">
                            Rechte der betroffenen Personen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m42">
                            Begriffsdefinitionen
                        </a>
                    </li>
                </ul>
                <h2 id="m3">Verantwortlicher</h2>
                <p>
                    Jörg Freier
                    <br />
                    Fleisch und Wurstwaren
                    <br />
                    Kammerrat Beck Strasse 8<br />
                    09376 Oelsnitz
                </p>
                E-Mail-Adresse:{" "}
                <p>
                    <a href="mailto:fleischereifreier@gmail.com">fleischereifreier@gmail.com</a>
                </p>
                Impressum:{" "}
                <p>
                    <a href="https://www.fleischerei-freier.de/impressum" target="_blank">
                        https://www.fleischerei-freier.de/impressum
                    </a>
                </p>
                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>
                    Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
                    zusammen und verweist auf die betroffenen Personen.
                </p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Inhaltsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-/Kommunikationsdaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                    <li>Kommunikationspartner.</li>
                    <li>Nutzer.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                    <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
                    <li>Kontaktanfragen und Kommunikation.</li>
                    <li>Feedback.</li>
                    <li>Marketing.</li>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                </ul>
                <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                <p>
                    Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
                    personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der
                    DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten
                    ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
                    Datenschutzerklärung mit.
                </p>
                <ul>
                    <li>
                        <strong>
                            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)
                        </strong>{" "}
                        - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene
                        Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
                        betroffenen Person erfolgen.
                    </li>
                    <li>
                        <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung
                        ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche
                        unterliegt.
                    </li>
                    <li>
                        <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung
                        ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
                        erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen
                        Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                    </li>
                </ul>
                <p>
                    Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen
                    zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                    personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
                    insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
                    Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung
                    für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
                    einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
                    Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung
                    oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner
                    können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
                </p>
                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>
                    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik,
                    der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
                    sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte
                    und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem
                    Risiko angemessenes Schutzniveau zu gewährleisten.
                </p>
                <p>
                    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                    Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als
                    auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
                    ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                    Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
                    gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                    Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                    Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
                </p>
                <p>
                    SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen,
                    nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix
                    https:// in der Adresszeile Ihres Browsers.
                </p>
                <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
                <p>
                    Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere
                    Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder
                    sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit IT-Aufgaben
                    beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden
                    werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere
                    entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern
                    Ihrer Daten ab.
                </p>
                <h2 id="m24">Datenverarbeitung in Drittländern</h2>
                <p>
                    Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen
                    Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten
                    Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
                    Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.{" "}
                </p>
                <p>
                    Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher
                    Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten
                    Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der
                    EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen
                    Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission:{" "}
                    <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                        target="_blank"
                    >
                        https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                    </a>
                    ).
                </p>
                <h2 id="m12">Löschung von Daten</h2>
                <p>
                    Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren
                    zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
                    (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
                    erforderlich sind).
                </p>
                <p>
                    Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                    erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
                    gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
                    steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
                    oder juristischen Person erforderlich ist.
                </p>
                <p>
                    Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten
                    beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.
                </p>
                <h2 id="m134">Einsatz von Cookies</h2>
                <p>
                    Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten
                    speichern und Informationen aus den Endgeräten auslesen. Z.B. um den Login-Status in einem
                    Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
                    Funktionen eines Onlineangebotes speichern. Cookies können ferner zu unterschiedlichen Zwecken
                    eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von
                    Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.{" "}
                </p>
                <p>
                    <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen
                    Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn
                    diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das
                    Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um
                    dem den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot)
                    zur Verfügung zu stellen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
                    kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
                </p>
                <p>
                    <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher
                    datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von
                    Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer
                    einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
                    Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten
                    Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
                    seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen
                    Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen
                    Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber
                    klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und
                    Verarbeitungsprozessen auf.
                </p>
                <p>
                    <strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von
                    Cookies unterschieden:
                </p>
                <ul>
                    <li>
                        <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies
                        werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät
                        (z.B. Browser oder mobile Applikation) geschlossen hat.
                    </li>
                    <li>
                        <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                        Endgerätes gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte
                        Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die
                        mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern
                        wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im
                        Rahmen der Einholung der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent
                        sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                    </li>
                </ul>
                <p>
                    <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von
                    ihnen abgegebenen Einwilligungen jederzeit Widerrufen und zudem einen Widerspruch gegen die
                    Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO einlegen (weitere Hinweise zum
                    Widerspruch erfolgen im Rahmen dieser Datenschutzerklärung). Nutzer können Ihren Widerspruch auch
                    mittels der Einstellungen Ihres Browsers erklären.
                </p>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen ein
                        Verfahren zum Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der
                        Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des
                        Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt sowie
                        von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die Einwilligungserklärung
                        gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung
                        entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann
                        serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer
                        Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
                        Vorbehaltlich individueller Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die
                        folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren betragen.
                        Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
                        Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von Cookies
                        und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.
                    </li>
                </ul>
                <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                <p>
                    Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von
                    einem oder mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten
                    Servern) das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und
                    Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie
                    Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
                </p>
                <p>
                    Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer
                    unseres Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der
                    Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte
                    von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder
                    von Webseiten getätigten Eingaben.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in Onlineformularen);
                        Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit.
                    </li>
                    <li>
                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Wir selbst (bzw. unser
                        Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles).
                        Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum
                        und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
                        nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                        Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen
                        zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
                        (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
                        anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen;{" "}
                        <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30
                        Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
                        Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                        Löschung ausgenommen.
                    </li>
                </ul>
                <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
                <p>
                    Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien)
                    sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
                    Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter
                    Maßnahmen erforderlich ist.
                </p>
                <p>
                    Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen
                    von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen
                    Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der
                    berechtigten Interessen an der Beantwortung der Anfragen und Pflege von Nutzer- bzw.
                    Geschäftsbeziehungen.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten
                        (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Kommunikationspartner.
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Erbringung
                        vertraglicher Leistungen und Kundenservice.
                    </li>
                    <li>
                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                        S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO); Rechtliche
                        Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere
                        Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
                        mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem Zweck verarbeiten wir
                        personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher Geschäftsbeziehungen, soweit
                        dies zu deren Erfüllung erforderlich ist und im Übrigen auf Grundlage unserer berechtigten
                        Interessen sowie der Interessen der Kommunikationspartner an der Beantwortung der Anliegen und
                        unserer gesetzlichen Aufbewahrungspflichten.
                    </li>
                </ul>
                <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
                <p>
                    Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten
                    der Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns
                    anzubieten.
                </p>
                <p>
                    Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union
                    verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die
                    Durchsetzung der Rechte der Nutzer erschwert werden könnte.
                </p>
                <p>
                    Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs-
                    und Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
                    Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet
                    werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den
                    Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern
                    der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert
                    werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
                    Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind
                    und bei diesen eingeloggt sind).
                </p>
                <p>
                    Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
                    Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der
                    Betreiber der jeweiligen Netzwerke.
                </p>
                <p>
                    Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf
                    hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter
                    haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen
                    und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern);
                        Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
                        Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                        IP-Adressen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Feedback (z.B.
                        Sammeln von Feedback via Online-Formular); Marketing.
                    </li>
                    <li>
                        <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen Netzwerks Facebook - Wir sind
                        gemeinsam mit Meta Platforms Ireland Limited für die Erhebung (jedoch nicht die weitere
                        Verarbeitung) von Daten der Besucher unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu
                        diesen Daten gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen oder mit
                        denen sie interagieren, oder die von ihnen vorgenommenen Handlungen (siehe unter „Von dir und
                        anderen getätigte und bereitgestellte Dinge“ in der Facebook-Datenrichtlinie:{" "}
                        <a href="https://www.facebook.com/policy" target="_blank">
                            https://www.facebook.com/policy
                        </a>
                        ), sowie Informationen über die von den Nutzern genutzten Geräte (z. B. IP-Adressen,
                        Betriebssystem, Browsertyp, Spracheinstellungen, Cookie-Daten; siehe unter „Geräteinformationen“
                        in der Facebook-Datenrichtlinie:{" "}
                        <a href="https://www.facebook.com/policy" target="_blank">
                            https://www.facebook.com/policy
                        </a>
                        ). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese Informationen?“ erläutert,
                        erhebt und verwendet Facebook Informationen auch, um Analysedienste, so genannte
                        "Seiten-Insights", für Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber
                        erhalten, wie Personen mit ihren Seiten und mit den mit ihnen verbundenen Inhalten interagieren.
                        Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen ("Informationen zu
                        Seiten-Insights",{" "}
                        <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank">
                            https://www.facebook.com/legal/terms/page_controller_addendum
                        </a>
                        ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook beachten muss und in
                        der Facebook sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z.
                        B. Auskünfte oder Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer
                        (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger
                        Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook nicht eingeschränkt. Weitere
                        Hinweise finden sich in den "Informationen zu Seiten-Insights" (
                        <a
                            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                            target="_blank"
                        >
                            https://www.facebook.com/legal/terms/information_about_page_insights_data
                        </a>
                        ); <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand
                        Canal Harbour, Dublin 2, Irland; <strong>Website:</strong>{" "}
                        <a href="https://www.facebook.com" target="_blank">
                            https://www.facebook.com
                        </a>
                        ; <strong>Datenschutzerklärung:</strong>{" "}
                        <a href="https://www.facebook.com/about/privacy" target="_blank">
                            https://www.facebook.com/about/privacy
                        </a>
                        ;{" "}
                        <strong>
                            Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
                            Drittländern):
                        </strong>{" "}
                        <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank">
                            https://www.facebook.com/legal/EU_data_transfer_addendum
                        </a>
                        ; <strong>Weitere Informationen:</strong> Vereinbarung gemeinsamer Verantwortlichkeit:{" "}
                        <a
                            href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                            target="_blank"
                        >
                            https://www.facebook.com/legal/terms/information_about_page_insights_data
                        </a>
                        .
                    </li>
                </ul>
                <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
                <p>
                    Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir
                    passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
                    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
                    Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung
                    erforderlich wird.
                </p>
                <p>
                    Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
                    Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können
                    und bitten die Angaben vor Kontaktaufnahme zu prüfen.
                </p>
                <h2 id="m10">Rechte der betroffenen Personen</h2>
                <p>
                    Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15
                    bis 21 DSGVO ergeben:
                </p>
                <ul>
                    <li>
                        <strong>
                            Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                            ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                            aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
                            auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                            personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht,
                            jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
                            zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit
                            solcher Direktwerbung in Verbindung steht.
                        </strong>
                    </li>
                    <li>
                        <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen
                        jederzeit zu widerrufen.
                    </li>
                    <li>
                        <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
                        Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                    </li>
                    <li>
                        <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das
                        Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
                        betreffenden unrichtigen Daten zu verlangen.
                    </li>
                    <li>
                        <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe
                        der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich
                        gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
                        Verarbeitung der Daten zu verlangen.
                    </li>
                    <li>
                        <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die
                        Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
                        gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen
                        Verantwortlichen zu fordern.
                    </li>
                    <li>
                        <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                        verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres
                        Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
                        Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
                    </li>
                </ul>
                <h2 id="m42">Begriffsdefinitionen</h2>
                <p>
                    In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
                    Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
                    definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen
                    dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
                </p>
                <ul class="glossary">
                    <li>
                        <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die
                        sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene
                        Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder
                        indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
                        zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
                        Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
                        psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person
                        sind.{" "}
                    </li>
                    <li>
                        <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische
                        Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
                        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{" "}
                    </li>
                    <li>
                        <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
                        Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten,
                        sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.{" "}
                    </li>
                </ul>
                <p class="seal">
                    <a
                        href="https://datenschutz-generator.de/"
                        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        <img
                            src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
                            alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                            width="250"
                            height="250"
                        />
                    </a>
                </p>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Datenschutz;
